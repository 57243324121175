import React from 'react'
import ImgMacBook from '../assets/macbook.webp'

export default function Section4(props) {
    return (
        <section ref={props.sectionRef} id='projects' class={` ${(props.hg) ? 'min-h-[900px]' : 'nav-sm:min-h-screen '} relative flex bg-[#3E3E3E] section4 w-full overflow-hidden`} >
            <div className='w-full nav-sm:flex items-center justify-around mt-[70px]'>
                <div className='flex h-full w-full items-center justify-center '>
                    <div className='w-full nav-sm:w-[46%] relative flex items-center justify-center hidden nav-sm:block mt-[70px] '>
                        <img src={ImgMacBook} className='left-[-24px] max-h-[80vh] pb-2 nav-sm:pt-2' loading="lazy"/>
                    </div>

                    <div className='flex flex-col items-left justify-center w-full nav-sm:w-[54%] mt-[16%] nav-sm:mt-[5%] '>

                        <div className='pl-[38px] md:pl-[62px] nav-sm:pl-[70px] c-lg:pl-[94px]'>

                            <div className='flex flex-col items-left justify-center w-full block nav-sm:hidden pr-[24px] md:pr-[62px] '>
                                <p className='text-[36px] md:text-[50px] c-xl:text-[60px] text-white w-full font-bold leading-[1.38] nav-sm:leading-[1] '>ALL OF OUR PROJECTS ARE CONFIDENTIAL</p>
                                <p className='text-[18px] md:text-[24px] c-xl:text-[42px] font-medium text-[#929292] mt-[22px]'>Your project and ideas are safe with us</p>
                                <p className='text-[16px] md:text-[20px] c-xl:text-[30px] text-[#929292] mt-[16px] c-md:mt-[22px] pr-0 c-hd:pr-[40px]'>We guarantee complete client confidentiality for as long as necessary, ensuring your privacy is maintained with the highest level of professionalism and discretion.</p>
                            </div>

                            <div className='flex flex-col items-left justify-center w-full hidden nav-sm:block pr-[24px] md:pr-[50px] '>
                                <p className='text-[36px] md:text-[44px] c-lg:text-[50px] c-xl:text-[60px] text-white w-full font-bold leading-[1.38] nav-sm:leading-[1] pr-[100px] c-xl:pr-[120px]'>ALL OF OUR PROJECTS ARE CONFIDENTIAL</p>
                                <p className='text-[18px] md:text-[24px] c-lg:text-[32px] c-xl:text-[42px] font-medium text-[#929292] mt-[32px] c-lg:mt-[42px]'>Your project and ideas are safe with us</p>
                                <p className='text-[16px] md:text-[18px] c-lg:text-[28px] c-xl:text-[30px] text-[#929292] mt-[16px] mt-[32px] c-lg:mt-[42px] pr-0 c-hd:pr-[60px]'>We guarantee complete client confidentiality for as long as necessary, ensuring your privacy is maintained with the highest level of professionalism and discretion.</p>
                            </div>

                        </div>

                        <img src={ImgMacBook} className=' h-full block nav-sm:hidden mt-[38px] nav-sm:mt-[50px] mb-[32] nav-sm:mb-[42px] w-[89%] c-md:w-[60%] macImage ' loading="lazy"/>
                    </div>
                </div>
            </div>

            <div class="absolute bg-colorSun sun sun-sm-bottom block nav-sm:hidden mt-[70px]"></div>
            <div class="absolute bg-colorSun sun sun-md-top-right-large hidden nav-sm:block mt-[40px]"></div>
        </section>
    )
}