import React from 'react'
import BackgroundImage from '../assets/bg_section_6.webp'
import MemojiNative from '../assets/notification/mamoji_native.webp'
import MemojiOptimize from '../assets/notification/memoji_optimize.webp'
import MemojiCreate from '../assets/notification/memoji_create.webp'
import { useState, useEffect, useRef } from 'react';

export default function Section6(props) {

    const [isSectionFullyVisible, setIsSectionFullyVisible] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const [isVisible2, setIsVisible2] = useState(false);
    const [isVisible3, setIsVisible3] = useState(false);
    const [isVisible4, setIsVisible4] = useState(false);
    const [isVisible5, setIsVisible5] = useState(false);

    const notificationRefs = useRef([]);

    useEffect(() => {
        if (isSectionFullyVisible) {
            setIsVisible(true);

            const timeouts = [200, 450, 700, 900];
            const setFunctions = [setIsVisible2, setIsVisible3, setIsVisible4, setIsVisible5];

            setFunctions.forEach((setFunc, index) => {
                setTimeout(() => {
                    setFunc(true);
                }, timeouts[index]);
            });
        }
    }, [isSectionFullyVisible]);


    useEffect(() => {

        if (props.inView) {
            setTimeout(() => {
                setIsSectionFullyVisible(true);
            }, 200);
        }
    }, [props.inView]);

    const [titleMarginTop, setTitleMarginTop] = useState(50)

    useEffect(() => {
        const notificationContainer = document.getElementById("notifications");
        if (notificationContainer && props.clientHeight) {
            const containerHeight = notificationContainer.offsetHeight;
            const diff = Math.abs(containerHeight - props.clientHeight);
            
            if (diff < 228 && props.clientWidth >= 1280) {
                setTitleMarginTop(calcMarginTop(diff))
            } else {
                setTitleMarginTop(getTitleDefaultMarginTop());
            }
        } else {

        }
    }, [props.clientHeight]);

    function calcMarginTop(diff) {
        const marginTop = getTitleDefaultMarginTop();
        return Math.min(Math.abs(diff - marginTop), 36);
    }

    function getTitleDefaultMarginTop() {
        var marginTop = 52;
        if (props.clientWidth >= 640) {
            marginTop = 56;
        }
        if (props.clientWidth >= 1280) {
            marginTop = 90;
        }
        if (props.clientWidth >= 1919) {
            marginTop = 125;
        }
        return marginTop;
    }


    function getNotifContainerWidth() {
        if (props.clientHeight >= props.clientWidth * 0.9) {
            return 'w-full sm:w-[80%] md:w-[55%] nav-sm:w-[50%] c-hd:w-[50%] c-lg:w-[45%] c-xl:w-[43%] '
        } else {
            return 'w-full sm:w-[80%] md:w-[55%] nav-sm:w-[50%] c-hd:w-[50%] c-lg:w-[35%] c-xl:w-[29%] '
        }
    }

    return (
        <section id="notifications" ref={props.sectionRef} class={`${(props.hg) ? '' : 'min-h-screen '} section6 relative w-full bg-no-repeat bg-cover flex flex-col `} >

            <img src={BackgroundImage} className="absolute inset-0 w-full h-full object-cover filter z-0" loading="lazy"/>
            <div className='h-full w-full flex flex-1 flex-col items-center justify-start z-20'>

                <div className='w-full flex flex-col items-center justify-center' style={{marginTop: `${titleMarginTop}px`}}>
                    <p className='w-full text-center notifs-title-size font-bold text-white px-[36px] '><b>APPS WITHOUT COMPROMISES</b></p>
                    <p className='w-full text-center notifs-subtitle-size text-white mt-[16px] c-md:mt-[26px] c-lg:mt-[34px]'><b>NOTCH</b> up your mobile experience!</p>
                </div>

                <div ref={props.inViewRef} className='w-full items-center justify-center h-full flex flex-1'>
                    <div className={` ${getNotifContainerWidth()} flex flex-col items-center justify-center duration-300 px-[24px] nav-sm:px-4 z-10  my-[30px] `}>
                        <div id={"notifs-container"} className='relative flex flex-col items-center justify-center'>
                            <div className='absolute w-full h-full'>
                                <NotificationItem ref={(el) => notificationRefs.current[0] = el} memoji={MemojiNative} title={"Navigate"} description={"We guide you through the complexities of digital technology for informed decision-making."} isVisible={isVisible} />
                                <NotificationItem ref={(el) => notificationRefs.current[1] = el} memoji={MemojiOptimize} title={"Optimize"} description={"We streamline operations and utilize resources efficiently for optimal business performance."} isVisible={isVisible2} />
                                <NotificationItem ref={(el) => notificationRefs.current[2] = el} memoji={MemojiCreate} title={"Transform"} description={"Leveraging cutting-edge technology, we revamp your mobile platforms for enhanced visibility and reach."} isVisible={isVisible3} />
                                <NotificationItem ref={(el) => notificationRefs.current[3] = el} memoji={MemojiOptimize} title={"Create"} description={"Our team designs intuitive interfaces that drive user engagement and foster loyalty."} isVisible={isVisible4} />
                                <NotificationItem ref={(el) => notificationRefs.current[4] = el} memoji={MemojiNative} title={"Harness"} description={"Embrace the latest tech advancements with us to stay ahead and drive growth in your industry."} isVisible={isVisible5} />
                            </div>

                            <div className='w-full h-full opacity-[0.01]'>
                                <NotificationItem ref={(el) => notificationRefs.current[10] = el} memoji={MemojiNative} title={"Navigate"} description={"We guide you through the complexities of digital technology for informed decision-making."} isVisible={true} />
                                <NotificationItem ref={(el) => notificationRefs.current[11] = el} memoji={MemojiOptimize} title={"Optimize"} description={"We streamline operations and utilize resources efficiently for optimal business performance."} isVisible={true} />
                                <NotificationItem ref={(el) => notificationRefs.current[12] = el} memoji={MemojiCreate} title={"Transform"} description={"Leveraging cutting-edge technology, we revamp your mobile platforms for enhanced visibility and reach."} isVisible={true} />
                                <NotificationItem ref={(el) => notificationRefs.current[13] = el} memoji={MemojiOptimize} title={"Create"} description={"Our team designs intuitive interfaces that drive user engagement and foster loyalty."} isVisible={true} />
                                <NotificationItem ref={(el) => notificationRefs.current[14] = el} memoji={MemojiNative} title={"Harness"} description={"Embrace the latest tech advancements with us to stay ahead and drive growth in your industry."} isVisible={true} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

function NotificationItem({ memoji, title, description, isVisible, ref }) {

    const itemClasses = `notif-item ${isVisible ? 'visible' : ''}`;

    return (
        <div ref={ref} className={`${itemClasses} w-full flex bg-[#ffffff29] notif-item-vetical-padding rounded-[34px] notif-background mb-[4px] nav-sm:mb-[9px]`}>
            <div className='notif-item-image-size flex-shrink-0'>
                <img src={memoji} className='h-full w-full' loading="lazy"/>
            </div>
            <div className='flex flex-col w-full'>
                <p className='notif-title-font-size text-white font-medium'>{title}</p>
                <p className='notif-title-description-size text-white mt-1'>{description}</p>
            </div>
        </div>
    );
}

