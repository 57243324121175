import React from 'react'
import { useState, useRef, useEffect } from 'react'
import iphoneFrame from '../assets/iphone_frame.webp'
import icDelete from '../assets/ic_delete.webp'
import buttonUpload from '../assets/button_upload.webp'
import icUser from '../assets/ic_user_join.webp'
import icArrowLeft from '../assets/icon/ic_arrow_left.svg'
import icApplicationSent from '../assets/icon/ic_application_sent.webp'
import icArrowDown from '../assets/icon/ic_arrow_down.svg'
import textGravisun from '../assets/join-popup-gravisun.webp'
import { db, storage } from './firebase';
import { collection, addDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import debounce from 'lodash/debounce';


export default function JoinUsPopup({ isJoinPopupVisible, toggleJoinPopup }) {

    // window.open('https://www.youtube.com/watch?v=dQw4w9WgXcQ', '_blank');
    const [isClosing, setIsClosing] = useState(false);
    const handleToggleJoinPopup = () => {
        if (isJoinPopupVisible) {
            setIsClosing(true);
            setTimeout(() => {
                setIsClosing(false);
                toggleJoinPopup();
            }, 250);
        } else {
            toggleJoinPopup();
        }
    };

    const [isLoading, setIsLoading] = useState(false);
    const [formUploadProgress, setFormUploadProgress] = useState(5)

    useEffect(() => {
        let intervalId;

        if (isLoading) {
            intervalId = setInterval(() => {
                setFormUploadProgress((p) => Math.min(100, p + 5));
            }, 1000);
        }

        return () => clearInterval(intervalId);
    }, [isLoading]);

    const [formData, setFormData] = useState({
        fullName: '',
        email: '',
        phoneNumber: '',
        document: null,
        link: ''
    });

    const handleChange = (e) => {
        const { name, value, files } = e.target;

        const maxFileSize = 16 * 1024 * 1024;

        if (files && files.length > 0) {
            const file = files[0];
            if (file.size > maxFileSize) {
                e.target.value = '';
                return;
            } else {
                setFormData({
                    ...formData,
                    [name]: file,
                });
            }
        } else if (files && files.length === 0) {

        } else {
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };

    const handleDelete = () => {
        setFormData({ ...formData, document: null });
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        setIsLoading(true);

        let documentUrl = null;
        let newFileName = null;

        if (formData.document) {
            const fileExt = formData.document.name.split('.').pop();
            newFileName = `${formData.fullName}_${Date.now()}.${fileExt}`;
            const storageRef = ref(storage, `documents/${newFileName}`);
            const snapshot = await uploadBytes(storageRef, formData.document);
            documentUrl = await getDownloadURL(snapshot.ref);
        }

        try {
            const docRef = await addDoc(collection(db, 'members'), {
                fullName: formData.fullName,
                email: formData.email,
                phoneNumber: formData.phoneNumber,
                link: formData.link,
                documentName: newFileName,
                createdAt: new Date()
            });
            // Reset form after successful submission
            setFormData({
                fullName: '',
                email: '',
                phoneNumber: '',
                document: null,
                link: ''
            });
            if (fileInputRef.current) {
                fileInputRef.current.value = '';
            }

            setFormUploadProgress(100);
            setTimeout(() => {
                setIsLoading(false);
                toggleJoinPopup();
            }, 5000);

        } catch (e) {
            console.error('Error adding document: ', e);
        }
    };

    const fileInputRef = useRef(null);
    const handleImageClick = () => {
        fileInputRef.current.click();
    };


    const divRef = useRef(null);
    const [resizeTrigger, setResizeTrigger] = useState(1);
    const [prevWidth, setPrevWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            const currentWidth = window.innerWidth;
            if (currentWidth !== prevWidth) {
                setResizeTrigger(prev => prev + 1);
                setPrevWidth(currentWidth);
            }
        };

        const debouncedHandleResize = debounce(handleResize, 2);

        window.addEventListener('resize', debouncedHandleResize);

        return () => {
            window.removeEventListener('resize', debouncedHandleResize);
        };
    }, [prevWidth]);


    const formContainerParentRef = useRef(null);
    const formContainerRef = useRef(null);
    const handleFocus = (e) => {
        e.preventDefault();
        const formContainer = formContainerRef.current;
        const parentContainer = formContainerParentRef.current;
        const offsetTop = e.target.offsetTop;


        if (formContainer && parentContainer) {
            const formContainerHeight = formContainer.clientHeight;
            const formContainerScrollHeight = formContainer.scrollHeight;

            setTimeout(() => {
                if (formContainerScrollHeight > formContainerHeight) {

                    const computedStyle = getComputedStyle(document.documentElement);
                    const insetRight = computedStyle.getPropertyValue('env(safe-area-inset-bottom)');
                    const a = parseFloat(insetRight);

                    var extra = -16
                    const isFirstInput = e.target === formContainer.querySelector('input:first-of-type');

                    if (isFirstInput) {
                        formContainer.scrollTo({
                            top: 0,
                            behavior: 'smooth'
                        });
                    } else {
                        formContainer.scrollTo({
                            top: offsetTop - 16,
                            behavior: 'smooth'
                        });
                    }

                } else {
                    var extra = 42
                    const isFirstInput = e.target === formContainer.querySelector('input:first-of-type');

                    if (isFirstInput) {
                        if (window.innerHeight > 540) {
                            parentContainer.scrollTo({
                                top: 0,
                                behavior: 'smooth'
                            });
                        }
                    } else {
                        if (window.innerHeight > 540) {
                            parentContainer.scrollTo({
                                top: parentContainer.scrollTop + offsetTop + extra,
                                behavior: 'smooth'
                            });
                        }
                    }

                }
            }, 500);
        }
    };


    const handleScroll = (e) => {
        e.stopPropagation();
    };


    if (!isJoinPopupVisible) return null;

    const handleChildClick = (e) => {
        e.stopPropagation();
    };

    return (
        <div
            onScroll={handleScroll}
            ref={divRef}
            onClick={handleToggleJoinPopup}
            className={`max-h-full pt-[80px] fixed inset-0 z-50 flex items-end justify-center backdrop-blur-sm bg-black bg-opacity-50 overflow-hidden `} >

            <div ref={formContainerParentRef} className={`overflow-y-auto scrollbar-hide max-h-full relative w-full md:w-[94%] lg:w-5/6 xl:w-4/5 2xl:w-[90%] bg-white rounded-t-[6vw] md:rounded-t-[3vw]  c-xl:rounded-t-[2vw] pb-0 mb-0 transform transition-transform duration-300 ${!isClosing ? 'popup-enter' : 'popup-exit'}`} onClick={handleChildClick}>
                <button
                    className="absolute top-2 right-2 text-black text-xl cursor-pointer"
                    onClick={handleToggleJoinPopup}
                >
                </button>
                <div className='flex h-full '>
                    <div className='w-full h-full relative '>

                        <div className='w-full join-up-title-padding'>
                            <div className='flex justify-between items-center'>
                                <h2 className="text-[22px] nav-sm:text-[32px] font-bold sm:hidden leading-[1]">BE A PART<br />OF OUR DIGITAL WORLD</h2>
                                <h2 className="text-[36px] c-xl:text-[54px] font-bold hidden sm:block leading-[1]">BE A PART OF OUR DIGITAL WORLD</h2>
                                <img src={icArrowDown} className='size-[48px] p-3 cursor-pointer' onClick={handleToggleJoinPopup} />
                            </div>

                            {
                                (isLoading) ? <p className='text-[14px] nav-sm:text-[22px] c-xl:text-[30px] mt-[18px]'>Your application is in progress, we will contact you as soon <br />as we can!</p> :
                                    <p className='text-[16px] nav-sm:text-[22px] c-xl:text-[30px] mt-[18px]'>Send you application and let us know <br />in what you are the best </p>
                            }

                            <p className='text-[#3D3D3D] opacity-50 text-sm mt-[24px]'>
                                {
                                    (isLoading) ? "Stay tuned!" : "Are you ready?"
                                }
                            </p>
                        </div>

                        <div key={`${resizeTrigger}`} className='flex justify-center c-lg:justify-end w-full h-full'>
                            <div className='relative                
                                join-us-iphone-ratio-h-bigger 
                                aspect-iframe                           
                                mx-[24px]
                                nav-sm:mx-0'>
                                <img src={iphoneFrame} className='absolute w-full h-full bottom-0' />

                                <div class={`absolute top-0 left-0 w-full flex items-center duration-300 join-us-loading-container-padding ${(!isLoading) ? 'hidden' : ''}`}>
                                    <JoinUsLoading progress={formUploadProgress} />
                                </div>

                                <div className={`h-[82%] absolute top-[18%] w-full flex justify-center duration-300 overflow-hidden ${isLoading ? 'hidden' : ''} duration-300 overflow-hidden ${isLoading ? 'hidden' : ''}`}>
                                    <div ref={formContainerRef} className='relative w-[88%] px-[5%] h-full overflow-y-auto ' >
                                        <JoinUsForm
                                            handleFocus={handleFocus}
                                            handleSubmit={handleSubmit}
                                            handleChange={handleChange}
                                            handleImageClick={handleImageClick}
                                            fileInputRef={fileInputRef}
                                            handleDelete={handleDelete}
                                            formData={formData}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className='flex relative click-to-dismiss-visibility'>
                                <div className='flex  items-center h-[40px] min-w-[210px] cursor-pointer opacity-[0.001]' onClick={handleToggleJoinPopup}>
                                    <img src={icArrowLeft} className='w-3 h-4 opacity-50 arrow1' />
                                    <img src={icArrowLeft} className='w-3 h-4 arrow2 mr-2' />
                                    <p className='text-colorBlack50 text-[20px] ls4 mr-[24px]'>Click to dismiss</p>

                                </div>
                                <div className='w-[42px] h-[21%] absolute top-[54%] right-[100%] cursor-pointer z-21 ' onClick={handleToggleJoinPopup}></div>

                            </div>

                            <div className='flex absolute top-[71%] c-lg:mt-[12px]  itemx-center click-to-dismiss-visibility'>
                                <div className='flex items-center h-[40px] w-[210px] cursor-pointer ' onClick={handleToggleJoinPopup}>
                                    <img src={icArrowLeft} className='w-[24px] h-[24px] opacity-50 arrow1' />
                                    <img src={icArrowLeft} className='w-[24px] h-[24px] arrow2 mr-2' />
                                    <p className='text-colorBlack50 text-[20px] ls4 mr-[24px]'>Click to dismiss</p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className=' absolute md:w-[94%] lg:w-5/6 xl:w-4/5 2xl:w-[90%] left-1/2 transform -translate-x-1/2 click-to-dismiss-visibility'>
                <img src={textGravisun} className={`join-us-gravisun-text-size ${!isClosing ? '' : 'hidden'}`} />
            </div>
        </div>
    )
}


export function JoinUsLoading({ progress }) {
    function createHeight() {
        return `${parseInt(progress)}%`
    }
    return (
        <div className='w-full bg-black flex flex-col items-center justify-center join-us-loading-radius z-20  '>

            <div className='flex items-center justify-center '>
                <p className='text-[14px] nav-sm:text-[20px] c-xl:text-[28px] c-xl text-colorWhite60'>{(progress == 100) ? "Sent! Thank you!" : "Your application is sending"} </p>
                <img src={icApplicationSent} className='w-6 h-4 mx-1' />

            </div>

            <div className='bg-[#72716f] w-full h-2 rounded-lg join-us-loading-progress-line-margin-top nav-sm:mt-[84px]'>
                <div className='h-full bg-white rounded-lg duration-500' style={{ width: createHeight() }}></div>
            </div>

        </div>
    )
}

export function JoinUsForm({ handleFocus, handleSubmit, handleChange, handleImageClick, fileInputRef, handleDelete, formData }) {

    const lastInputRef = useRef(null);

    return (
        <form onSubmit={handleSubmit} className="space-y-3 joinus-pb">
            <div className='flex join-input-container join-input user-name-field flex items-center'>
                <img src={icUser} className='join-us-name-field-image-size' />
                <input
                    onFocus={handleFocus}
                    type="text"
                    placeholder='Your Name'
                    name="fullName"
                    value={formData.fullName}
                    onChange={handleChange}
                    class="mt-1 block w-full min-h-[50px] h-full join-us-form-field-text-size"
                    required
                />
            </div>

            <div className='join-input-container user-normal-field flex items-center'>
                <input
                    type="email"
                    name="email"
                    onFocus={handleFocus}
                    placeholder='Email'
                    value={formData.email}
                    onChange={handleChange}
                    class="mt-1 block w-full join-us-form-field-text-size"
                    required
                />
            </div>

            <div className='join-input-container user-normal-field flex items-center'>
                <input
                    type="tel"
                    name="phoneNumber"
                    onFocus={handleFocus}
                    placeholder='Phone'
                    value={formData.phoneNumber}
                    onChange={handleChange}
                    class="mt-1 block w-full  join-us-form-field-text-size"
                    required
                    pattern="[0-9]*"
                    onKeyPress={(e) => {
                        if (!/[0-9]/.test(e.key)) {
                            e.preventDefault();
                        }
                    }}
                />
            </div>

            <div className='flex items-center upload-file-field'>
                <div className='flex join-input-container justify-center items-center upload-file-buttun'>
                    <div
                        className="flex flex-col block text-gray-700 cursor-pointer items-center justify-center"
                        onClick={handleImageClick}
                    >
                        <img src={buttonUpload} className='size-[80%] ' />
                        <p className='text-colorIphoneBlue join-us-form-field-upload-text-size'>Upload</p>
                    </div>
                    <input
                        ref={fileInputRef}
                        id="fileInput"
                        type="file"
                        accept="application/pdf, application/msword, image/png, image/jpeg"
                        name="document"
                        onChange={handleChange}
                        class=" mt-1 block w-full text-sm text-gray-900 cursor-pointer join-input hidden "
                        required
                    />
                </div>

                {(formData.document != null) &&
                    <div className='ml-2 flex w-full h-full items-center justify-center join-input-container '>


                        <div className='flex w-full h-full items-center justify-between overflow-hidden'>
                            <p className='mx-2 text-[#949494] join-us-form-field-upload-text-size file-name-max-char w-[108px] m12p:w-[120px] mxr:w-[130px] m14p:w-[80%] '>{`${formData.document.name}`}</p>
                            <img
                                onClick={handleDelete}
                                src={icDelete} className='cursor-pointer h-[32%] mr-2' />
                        </div>


                    </div>
                }

            </div>

            <div className='join-input-container user-normal-field flex items-center mt-1'>
                <input
                    type="text"
                    placeholder='Link'
                    name="link"
                    onFocus={handleFocus}
                    value={formData.link}
                    onChange={handleChange}
                    pattern="^[\w\-]+(\.[\w\-]+)+[/#?]?.*$"
                    class=" block w-full join-us-form-field-text-size"
                />
            </div>

            <div ref={lastInputRef} className="join-input-container user-normal-field flex items-center">
                <button
                    type="submit"
                    className="py-1 text-colorIphoneBlue w-full text-left ">

                    <p className='!cursor-pointer join-us-form-field-text-size'>Apply</p>
                </button>
            </div>
        </form>
    )
}
