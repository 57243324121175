import React, { useEffect, useState } from 'react';

export default function Section3(props) {

    const [isVisible, setIsVisible] = useState(false);
    const [switchManagementOn, setSwitchManagementOn] = useState(false);
    const [switchOutsourceOn, setSwitchOutsourceOn] = useState(false);
    const [switchReworkOn, setSwitchReworkOn] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const section = document.getElementById('approach');
            if (section) {
                const windowHeight = window.innerHeight;
                const sectionTop = section.getBoundingClientRect().top + (windowHeight * 0.8);
                if (sectionTop < windowHeight) {
                    setIsVisible(true);
                }
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        if (isVisible) {
            const timeoutManagement = setTimeout(() => {
                setSwitchManagementOn(true);
            }, 420);

            const timeoutOutsource = setTimeout(() => {
                setSwitchOutsourceOn(true);
            }, 520);

            const timeoutRework = setTimeout(() => {
                setSwitchReworkOn(true);
            }, 720);

            return () => {
                clearTimeout(timeoutManagement);
                clearTimeout(timeoutOutsource);
                clearTimeout(timeoutRework);
            };
        }
    }, [isVisible]);

    return (
        <section ref={props.sectionRef} id="approach" className={` ${(props.hg) ? 'min-h-[900px]' : 'min-h-screen'} flex bg-colorBackWhite w-full section3`}>
            <div className=' flex-1 flex flex-col mt-[52px] c-md:mt-[56px] c-lg:mt-[90px] c-xl:mt-[125px]'>
                <div className='text-black text-center px-[48px]'>
                    <p className='text-[36px] c-lg:text-[36px] c-xl:text-[60px] text-colorSemiBlack font-bold'>HAVE YOU THOUGHT ABOUT THESE?</p>
                    <p className='text-[22px] c-lg:text-[30px] c-xl:text-[38px] text-colorSemiBlack mt-[32px] c-md:mt-[40px] c-lg:mt-[48px] hidden md:block w-full font-medium'>If not, don't worry - we've got you covered!</p>
                    <p className='text-[22px] c-lg:text-[30px] c-xl:text-[38px] text-colorSemiBlack mt-[32px] c-md:mt-[40px] c-lg:mt-[48px] block md:hidden font-medium'>If not, don’t worry<br />We are here to cover you!</p>
                </div>

                <div className='h-full w-full flex flex-col items-center justify-start '>
                    <p className='text-[14px] c-md:text-[18px] c-xl:text-[28px] mt-[8px] text-colorSemiBlack'>A peaceful night's sleep is unparalleled.</p>
                    <div className={`${(props.hg) ? '' : ''} h-full w-full flex text-center items-center justify-center mt-[-36px]`}>
                        <div className='w-full c-lg:flex items-start justify-start mt-[62px] mb-[50px] '>
                            <div className='w-full c-lg:w-1/3'>
                                <SwitchItem
                                    changeAllowed={isVisible}
                                    switchKey={switchManagementOn}
                                    title={"MANAGEMENT"}
                                    subtitle={"Planned project, organized workflow"}
                                    description={"Efficiently manage your app with strategic planning and development to avoid wasting time"}
                                    setSwitchKey={setSwitchManagementOn}
                                />
                            </div>
                            <div className='w-full c-lg:w-1/3 '>
                                <SwitchItem
                                    changeAllowed={isVisible}
                                    switchKey={switchOutsourceOn}
                                    title={"OUTSOURCE"}
                                    subtitle={"Fresh, powerful, more experienced"}
                                    description={"Enhance your team with our expert services while optimizing your budget"}
                                    setSwitchKey={setSwitchOutsourceOn}
                                />
                            </div>
                            <div className='w-full c-lg:w-1/3 '>
                                <SwitchItem
                                    changeAllowed={isVisible}
                                    switchKey={switchReworkOn}
                                    title={"REWORK"}
                                    subtitle={"Solutions, increased success rate"}
                                    description={"Complex problems that seem unsolvable can actually be untangled by starting over or resuming from a checkpoint"}
                                    setSwitchKey={setSwitchReworkOn}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

function SwitchItem({ changeAllowed, switchKey, setSwitchKey, title, subtitle, description }) {
    const [isOn, setIsOn] = useState(switchKey);

    useEffect(() => {
        setIsOn(switchKey);
    }, [switchKey]);

    useEffect(() => {
        if (!isOn && changeAllowed) {
            const timeout = setTimeout(() => {
                setIsOn(true);
                setSwitchKey(true);
            }, 850);
            return () => clearTimeout(timeout);
        }
    }, [isOn, setSwitchKey, changeAllowed]);

    const handleChange = () => {
        setIsOn(!isOn);
        setSwitchKey(!isOn);
    };

    return (
        <div className='flex flex-col text-left items-center justify-center align-middle mx-[34px] nav-sm:mx-[68px] pt-[25px] pb-[29px] c-lg:pt-[48px] c-lg:pb-[57px]'>

            <div className='flex justify-between w-full block c-lg:hidden '>
                <p className='text-[24px] font-semibold'>{title}</p>
                <input
                    checked={isOn}
                    onChange={handleChange}
                    type="checkbox"
                    id={`switch-${title}-sm`}
                    className='switch-input switch-input-sm'
                />
                <label htmlFor={`switch-${title}-sm`} className='switch-label switch-label-sm mt-2 ' />
            </div>

            <div className='flex flex-col items-center hidden c-lg:flex'>
                <input
                    checked={isOn}
                    onChange={handleChange}
                    type="checkbox"
                    id={`switch-${title}`}
                    className='switch-input'
                />
                <label htmlFor={`switch-${title}`} className='switch-label' />
                <p className='text-[48px] font-bold mt-[62px] text-colorSemiBlack'>{title}</p>
            </div>

            <p className='text-[14px] c-md:text-[16px] c-xl:text-[20px] w-full mt-[12px] c-lg:mt-[62px] c-lg:text-center tracking-ls4 text-colorSemiBlack font-bold'>{subtitle}</p>
            <p className='text-[14px] c-md:text-[14px] c-xl:text-[18px] w-full c-lg:w-[264px] c-xl:w-[330px] mt-2 c-lg:mt-[32px] px-0 text-colorTextSemiGray c-lg:text-center '>{description}</p>
        </div>
    );
}