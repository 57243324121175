import React from 'react'
import Logo from '../assets/logo.webp';
import LogoX from '../assets/footer/logo_x.svg';
import LogoYoutube from '../assets/footer/logo_youtube.svg';
import LogoThreads from '../assets/footer/logo_threads.svg';
import LogoMedium from '../assets/footer/logo_medium.svg';
import LogoLinkedin from '../assets/footer/logo_linkedin.svg';
import LogoInstagram from '../assets/footer/logo_instagram.svg';
import LogoFacebook from '../assets/footer/logo_facebook.svg';
import LogoTiktok from '../assets/footer/logo_tiktok.svg';

export default function Footer() {

    return (
        <section class='w-full pb-[100px] bg-white'>
            <div className='flex flex-col w-full h-full items-center'>
                <p className='text-colorBlack30 text-lg mt-8 mb-6 px-8 text-center'>We are happy to see you on our social media platforms</p>
                <div className="w-full c-md:flex justify-center px-8 gap-4 ">
                    <div className="flex flex-wrap justify-center gap-4">
                        <LogoItem logo={LogoLinkedin} destination={"https://www.linkedin.com/company/gravisun"} />
                        <LogoItem logo={LogoThreads} destination={"https://www.threads.net/@gravisun"} />
                        <LogoItem logo={LogoX} destination={"https://twitter.com/gravisun"} />
                        <LogoItem logo={LogoInstagram} destination={"https://www.instagram.com/gravisun/"} />
                    </div>
                    <div className="flex flex-wrap justify-center gap-4 mt-4 c-md:mt-0">
                        <LogoItem logo={LogoFacebook} destination={"http://facebook.com/gravisun"} />
                        <LogoItem logo={LogoMedium} destination={"https://medium.com/gravisun"} />
                        <LogoItem logo={LogoTiktok} destination={"https://www.tiktok.com/@gravisun"} />
                        <LogoItem logo={LogoYoutube} destination={"https://www.youtube.com/channel/UCQqRBGCsjDuhfUQtSbiIBZg"} />
                    </div>
                </div>

                <div className='flex flex-col sm:flex-row justify-around items-center md:h-24 w-full md:w-2/3 mt-16 md:mt-24 '>
                    <div>
                        <img src={Logo} className='w-[253px] h-[110px]' />
                        <p className='mt1 text-[#7F7F7F] text-[17px] font-sans'>
                            © 2024&nbsp;
                            <a href="#"
                                className="text-colorPrimary hover:text-blue-600"
                                onClick={(e) => {
                                    e.preventDefault();
                                    window.location.reload();
                                }}>
                                Gravisun
                            </a>
                        </p>
                    </div>

                    <div className='w-[200px] items-center justify-center mt-8 md:mt-0 mb-12 md:mb-0 flex flex-col'>
                        <div className='w-[148px] h-[60px]'>
                            <iframe className='w-[148px] h-[60px]' src='./clutch.html' title="Clutch Widget"></iframe>
                        </div>
                        <p className='mt-1 text-colorBlack30'>Leave your review on Clutch</p>
                    </div>
                </div>
            </div>

        </section>
    )
}


export function LogoItem(props) {

    const openLink = () => {
        window.open(props.destination, '_blank');
    };

    return (
        <img className='cursor-pointer size-10' src={props.logo} onClick={openLink} />
    )
}
