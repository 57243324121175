import { Link, Menu, X } from 'lucide-react'
import LogoLight from '../assets/logo.webp'
import LogoDark from '../assets/logo_dark.webp'
import { Link as ScrollLink, animateScroll as scroll } from 'react-scroll';
import React, { useState, useEffect, useRef } from 'react';
import Button from './Button'

const Nav = (props) => {

    let Links = [
        { name: "Digital World", path: "digital-world" },
        { name: "Services", path: "services" },
        { name: "Approach", path: "approach" },
        { name: "Projects", path: "projects" },
        { name: "Trust", path: "trust" },
        { name: "NOTCH", path: "notifications" },
        { name: "Team", path: "team" },
        { name: "Connect", path: "connect" },
    ];

    let [open, setOpen] = useState(false);
    const [activeSection, setActiveSection] = useState(Links[0].path);
    
    useEffect(() => {
        const handleScroll = () => {
            const sections = document.querySelectorAll("section");
            const observer = new IntersectionObserver(
                (entries) => {
                    entries.forEach((entry) => {
                        if (entry.isIntersecting) {
                            setActiveSection(entry.target.id);
                        }
                    });
                },
                { threshold: 0.7 }
            );
            sections.forEach((section) => {
                observer.observe(section);
            });
            return () => {
                sections.forEach((section) => {
                    observer.unobserve(section);
                });
            };
        };
        handleScroll();
    }, []);

    const navShadow = (input) => {
        if (input == '') {
            return '';
        } else {
            return 'shadow-md'
        }
    }

    const cache = useRef('bg-[#292929]');

    const backgroundColor = (input) => {

        switch (input) {
            case 'light':
                cache.current = "bg-[#ffffff]";
                return "bg-[#ffffff]";
            case 'dark':
                cache.current = "bg-[#000000]";
                return "bg-[#000000]";
            case 'gray':
                cache.current = "bg-[#292929]";
                return "bg-[#292929]";
            default:
                return cache.current;
        }
    }

    const navItemColor = (input) => {
        switch (input) {
            case 'light':
                return "text-navLink";
            case 'dark':
                return "text-[#ffffff]";
            default:
                return "text-[#ffffff]"
        }
    }

    const navLogo = (input) => {
        switch (input) {
            case 'light':
                return LogoLight;
            case 'dark':
                return LogoDark;
            default:
                return LogoDark
        }
    }

    const buttonColor = (input) => {
        switch (input) {
            case 'light':
                return "button-shadow";
            case 'dark':
                return "button-shadow-dark";
            default:
                return "button-shadow-normal";
        }
    }

    const iconTint = (input) => {
        switch (input) {
            case 'light':
                return "#000";
            case 'dark':
                return "#fff";
            default:
                return "#fff";
        }
    }

    useEffect(() => {
        const element = document.getElementById('nav-ul');

        if (element) {
            if (open) {
                element.classList.add('shadow-left-bottom');
            } else {
                element.classList.remove('shadow-left-bottom');
            }
        }
    }, [open]);

    const updatePropValue = () => {
        const screenSize = window.innerWidth;

        if (screenSize > 860) {
            setOpen(false);
        }
    };
    useEffect(() => {
        updatePropValue();
        window.addEventListener('resize', updatePropValue);
        return () => window.removeEventListener('resize', updatePropValue);
    }, []);


    const previousScrollY = useRef(0);

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollY = window.scrollY;

            if (currentScrollY > previousScrollY.current + 20 || currentScrollY < previousScrollY.current - 20) {
                setOpen(false);
            }

            previousScrollY.current = currentScrollY;
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      };

    return (
        <nav className={`navbar ${navShadow(props.theme)}`}>
            <div class={`h-full nav-sm:flex items-center justify-between ${backgroundColor(props.theme)}`}>
                <div className='w-[107px] h-full flex items-center justify-center ml-[28px] nav-sm:ml-[40px] c-lg:ml-[80px] c-xl:ml-[112px]'>
                    <div className="logo h-[50px] w-[107px] cursor-pointer" onClick={scrollToTop}>
                        <img src={navLogo(props.theme)} />
                    </div>
                </div>

                <div onClick={() => setOpen(!open)} className='text-3xl absolute right-8 top-[18px] m12p:top-[24px] cursor-pointer nav-sm:hidden'>
                    {
                        open ? <X color={iconTint(props.theme)} /> : <Menu color={iconTint(props.theme)} />
                    }
                </div>

                <ul id='nav-ul' className={
                    `nav-sm:flex ${(open) ? backgroundColor(props.theme) : ''}
                     nav-sm:items-center nav-sm:pb-0 pb-[24px] absolute nav-sm:static nav-sm:z-auto z-[-1] 
                     w-[287px] nav-sm:w-auto px-[48px] nav-sm:px-0
                     transition-all duration-300 ease-in ${open ? 'right-0 ' : 'right-[-100%]'}`
                }>
                    {
                        Links.map((item, index) => (

                            <NavItem key={index} onClick={() => {
                                setOpen(false);
                                setActiveSection(item.path);
                            }} textColor={navItemColor(props.theme)} name={item.name} path={item.path} isSelected={activeSection === item.path || (activeSection == "" && index == Links.length - 1)} />
                        ))
                    }
                    <div className='flex w-full nav-sm:w-auto items-center justify-center ml-0 nav-sm:ml-[32px] lg:ml-[50px] nav-sm:mr-[22px] c-lg:mr-[40px] nav-sm:mr-0 mt-[36px] m14p:mt-[45px] nav-sm:mt-0 '>
                        <Button onClick={props.onContactUsClicked} title="BOOK NOW" color={buttonColor(props.theme)} />
                    </div>

                </ul>
            </div>
        </nav>
    )
}

const NavItem = (props) => {

    const selectedStyle = (input) => {
        const baseStyle = 'nav-sm:ml-[12px] lg:ml-[24px] nav-sm:my-0 my-[14px] m14p:my-[18px] nav-underline';
        const selected = ' is-active';
        const textColor = props.textColor || '';
        return input ? `${baseStyle} ${selected}` : `${baseStyle} ${textColor}`;
    }

    return (
        <div>
            <li className={` ${selectedStyle(props.isSelected)} inline-block nav-sm:block`}>
                <ScrollLink onClick={() => props.onClick()} to={props.path} duration={300} className='text-[13px] c-lg:text-[14px] c-xl:text-[17px] cursor-pointer duration-300 font-sans'>
                    {props.name}
                </ScrollLink>
            </li>
        </div>
    )
}

export default Nav