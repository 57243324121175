import React from 'react'
import { useEffect, useState, useRef } from 'react'
import { X } from 'lucide-react'
import imgServiceDevelopment from '../assets/services/service-development-blured.webp'
import imgServiceDesign from '../assets/services/service-design-blured.webp'
import imgServiceManagement from '../assets/services/service-management-blured.webp'
import imgServiceOutsourcing from '../assets/services/service-outsourcing-blured.webp'
import imgServiceRework from '../assets/services/service-rework-blured.webp'
import imgServiceConsult from '../assets/services/service-consult-blured.webp'
import icArrowRight1 from '../assets/icon/ic_arrow_right1.svg'
import icArrowRight2 from '../assets/icon/ic_arrow_right2.svg'

export default function ServicePopup({ isServicesPopupVisible, toggleServicePopup, selectedServiceOrder, updateSelectedServiceOrder, popupStyle, setPopupStyle, clientHeight }) {

    const [animationKey, setAnimationKey] = useState(0);
    const titleRef = useRef(null);
    const contactUsButtonRef = useRef(null);
    const [isClosing, setIsClosing] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [isHovered, setIsHovered] = useState(false);

    useEffect(() => {
        const handleTitleChange = () => {
            if (isLoaded) {
                if (titleRef.current) {
                    titleRef.current.classList.remove('animated-paragraph-center-to-left');
                    void titleRef.current.offsetWidth;
                    titleRef.current.classList.add('animated-paragraph-center-to-left');
                }

                if (contactUsButtonRef.current) {
                    contactUsButtonRef.current.classList.remove('animated-paragraph-center-to-left');
                    void titleRef.current.offsetWidth;
                    contactUsButtonRef.current.classList.add('animated-paragraph-center-to-left');
                }
            }
        };

        handleTitleChange();
    }, [selectedServiceOrder, isLoaded]);

    useEffect(() => {
        if (isServicesPopupVisible) {
            setIsLoaded(false);
            setIsClosing(false);
        }
    }, [isServicesPopupVisible]);

    useEffect(() => {
        if (isServicesPopupVisible) {
            const timer = setTimeout(() => setIsLoaded(true), 500);
            return () => clearTimeout(timer);
        }
    }, [isServicesPopupVisible]);


    function handleClose(e, order) {
        setIsClosing(true);
        setTimeout(() => {
            toggleServicePopup(null, 0);
        }, 500);
    }

    const openCompanyEmail = (title) => {
        const email = 'info@gravisun.com';
        const subject = encodeURIComponent(title);
        const mailtoLink = `mailto:${email}?subject=${subject}`;
        window.open(mailtoLink, '_blank');
    };

    const backImage = () => {
        switch (selectedServiceOrder) {
            case 0:
                return imgServiceDevelopment;
            case 1:
                return imgServiceDesign;
            case 2:
                return imgServiceManagement;
            case 3:
                return imgServiceOutsourcing;
            case 4:
                return imgServiceRework;
            case 5:
                return imgServiceConsult;
            default:
                return imgServiceDesign;
        }
    }
    const servicesData = [
        {
            title: "Development",
            order: 0,
            subTitles: [
                "Native & Cross-platform mobile applications",
                "Quality Audits & Improvements",
                "Technical Consulting",
                "Frontend & Backend Support",
                "Manual Quality Assurance Testing",
                "DevOps Solutions"
            ],
            backImage: imgServiceDevelopment
        },
        {
            title: "Design & Vision",
            order: 1,
            subTitles: [
                "User Experience Assessment & Enhancements",
                "UI/UX Design",
                "Development of Component Library & Style Guide",
                "Branding"
            ],
            backImage: imgServiceDesign
        },
        {
            title: "Operation",
            order: 2,
            subTitles: [
                "Project Management",
                "Resource management",
                "Delivery Management"
            ],
            backImage: imgServiceManagement
        },
        {
            title: "Outsource",
            order: 3,
            subTitles: [
                "Mobile Development",
                "Web Development",
                "Project Management",
                "Quality Assurance & Testing",
                "UI/UX Design",
                "Graphic Design "
            ],
            backImage: imgServiceOutsourcing
        },
        {
            title: "Rework",
            order: 4,
            subTitles: [
                "Re-launch of Existing Products",
                "Reorganization of Underperforming Teams for Improved Efficiency",
                "Ongoing Project Enhancements",
                "Development of Customized Solutions for Optimized Release Processes",
            ],
            backImage: imgServiceRework
        },
        {
            title: "Consultation",
            order: 5,
            subTitles: [
                "App Design & Mobile Strategy ",
                "Technology Stack ",
                "Monetization Strategy",
                "Performance Analysis",
                "User Acquisition & Retention",
                "Compliance & Scalability"
            ],
            backImage: imgServiceConsult
        }
    ]

    return (
        <div className={`z-[1005] ${(isClosing) ? 'popup-closing' : 'popup-grow-content'} fixed inset-0 flex items-center justify-center bg-black z-50 w-full bg-white ${isServicesPopupVisible ? '' : 'hidden'}`} style={popupStyle}>
            <div className="relative w-full h-full">
                <img src={backImage()} className="absolute inset-0 w-full h-full object-cover filter z-0 scale-[1.05]" loading="lazy"/>
                <div className='absolute inset-0 w-full h-full bg-[#1F1F1F] opacity-[0.72]'></div>
                <div className="w-full h-full flex z-100">
                    <div className="hidden nav-sm:flex w-20 items-center justify-center ml-16">
                        <div className="relative flex flex-col items-center h-2/3">
                            <div className="w-1 h-full absolute py-2">
                                <div className="w-1 h-full bg-[#8B8B8B] opacity-50 rounded-lg"></div>
                            </div>

                            <div className="flex flex-col justify-between h-full z-10 items-center">
                                {[...Array(6)].map((_, index) => (
                                    <div key={index} className={`transition-all duration-500 ease-in-out flex items-center justify-center ${index === selectedServiceOrder ? 'w-5 h-5 bg-colorPrimary rounded-full' : 'w-3 h-3 border-2 border-[#E4E2DD] rounded-full'}`}> </div>
                                ))}
                            </div>
                        </div>
                    </div>

                    <div className="flex-grow w-full z-10">
                        <div className="absolute top-0 right-0 block nav-sm:hidden flex items-center justify-center w-[60px] h-[32px] mr-[32px] c-md:mr-[62px] service-minimize-mt" onClick={() => (handleClose(null, 0))} onMouseEnter={() => setIsHovered(true)}
                            onMouseLeave={() => setIsHovered(false)}>
                            {
                                (isHovered) ? <X color={"#7E7E7E"} className='size-[24px] ' /> : <p class="border-b border-colorTextGray text-colorTextGray text-[14px] cursor-pointer" >Minimize</p>
                            }
                        </div>

                        <div className='flex flex-col h-full mx-[32px] c-md:mx-[62px] nav-sm:ml-[130px] c-lg:ml-[200px] c-xl:ml-[200px] nav-sm:justify-center mt-[96px] nav-sm:mt-0'>
                            <div className='w-full'>
                                {isLoaded &&
                                    <p ref={titleRef} className='!cursor-pointer text-[46px] c-md:text-[55px] c-2lg:text-[90px] text-white font-bold tracking-lsservice' style={{
                                        opacity: (isLoaded) ? 0.01 : 0.01,
                                        marginTop: (clientHeight > 570) ? '24px' : '0px',
                                        marginBottom: (clientHeight > 570) ? '24px' : '12px',
                                    }}>{servicesData[selectedServiceOrder].title}</p>
                                }

                                <div className='h-[259px] nav-sm:h-[278px] c-2lg:h-[352px] w-full'>
                                    {isLoaded && servicesData[selectedServiceOrder].subTitles.map((item, index) => (
                                        <p
                                            key={`${animationKey}-${index}`}
                                            className='service-popup-p my-[14px] nav-sm:my-[20px] text-[18px] c-md:text-[20px] c-2lg:text-[28px] text-[#E4E2DD] animated-paragraph-center-to-left'
                                            style={{animationDelay: `${(index + 1) * 0.05}s`, opacity: 0}}
                                        >{item}</p>
                                    ))}
                                </div>

                                {isLoaded &&
                                    <div ref={contactUsButtonRef} className='w-full flex justify-center nav-sm:justify-start '>
                                        <div className='h-[57px] mt-[44px] text-[#E4E2DD] text-[18px] nav-sm:text-[24px] service-popup-p bg-[#18101057] py-[15px] nav-sm:py-[20px] px-[39px] nav-sm:px-[54px] rounded-full inline-block flex items-center cursor-pointer' onClick={() => openCompanyEmail(servicesData[selectedServiceOrder].title)}>
                                            <p className='!cursor-pointer'>Contact us</p>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>

                        <div className="block nav-sm:hidden absolute flex bottom-0 ml-[32px] c-md:ml-[62px] service-next-mb cursor-pointer service-description-next-pb" onClick={() => {
                            updateSelectedServiceOrder((selectedServiceOrder + 1) % 6)
                            setAnimationKey(prevKey => prevKey + 1);
                        }}>
                            <p className='!cursor-pointer text-colorTextGray'>{servicesData[(selectedServiceOrder + 1) % 6].title}</p>
                            <div className='flex items-center '>
                                <img src={icArrowRight1} className='w-5 h-5 arrow-next-1 ml-2' loading="lazy"/>
                                <img src={icArrowRight2} className='w-5 h-5 arrow-next-2 mr-2' loading="lazy"/>
                            </div>
                        </div>
                    </div>

                    <div className='w-1/3 c-2lg:w-[363px] z-10 hidden nav-sm:flex'>
                        <div className="relative h-full w-full">
                            <div className="absolute top-0 right-0 flex items-center justify-center w-[80px] h-[44px] mr-[65px] mt-[55px] cursor-pointer " onClick={() => (handleClose(null, 0))} onMouseEnter={() => setIsHovered(true)}
                                onMouseLeave={() => setIsHovered(false)}>
                                {(isHovered) ? <X color={"#7E7E7E"} className='size-[32px] ' /> :
                                    <p class="border-b border-colorTextGray text-colorTextGray ">Minimize</p>
                                }
                            </div>
                            <div className="absolute flex top-1/2 transform -translate-y-1/2 left-0 cursor-pointer" onClick={() => {
                                updateSelectedServiceOrder((selectedServiceOrder + 1) % 6)
                                setAnimationKey(prevKey => prevKey + 1);
                            }}>
                                <p className='!cursor-pointer text-colorTextGray'>{servicesData[(selectedServiceOrder + 1) % 6].title}</p>
                                <div className='flex items-center '>
                                    <img src={icArrowRight1} className='w-5 h-5 arrow-next-1 ml-2' loading="lazy"/>
                                    <img src={icArrowRight2} className='w-5 h-5 arrow-next-2 mr-2' loading="lazy"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
