import React from 'react'

const Button = (props) => {
  return (
      <button onClick= {props.onClick} class={`${props.color} text-[13px] c-lg:text-[14px] c-xl:text-[17px] font-semibold font-sans w-[142px] h-[42px] rounded-lg duration-500 text-colorPrimary nav-button`}>
        {props.title}
      </button>
  )
}

export default Button