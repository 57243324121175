import React from 'react'
import { useEffect, useState } from 'react';
import MockIphone from '../assets/mock_iphone2.webp'
import MockAndroid from '../assets/mock_android2.webp'
import GravisunTextImage from '../assets/gravisun-text.webp'


export default function Section1(props) {

  const [animate, setAnimate] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setAnimate(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  const handleThereIsMoreButtonClicked = () => {
    const nextSection = document.getElementById('services');

    if (nextSection) {
      nextSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const [sectionStyle, setSectionStyle] = useState({});

  useEffect(() => {
    function updateSectionHeight() {
      let style = {};
      if (props.hg) {
        style = { minHeight: '800px' };
      } else {
        const screenWidth = window.innerWidth;
        if (screenWidth > 860) {
          const minRatio = calculateMinRatio(screenWidth);
          const minHeight = screenWidth * minRatio + 400;
          const screenHeight = window.innerHeight;

          if (screenHeight < minHeight) {
            style = { height: `${minHeight}px` };
          } else {
            style = { height: `${props.clientHeight}px` };
          }
        }
      }

      setSectionStyle(style);
    }

    updateSectionHeight();
    window.addEventListener('resize', updateSectionHeight);

    return () => {
      window.removeEventListener('resize', updateSectionHeight);
    };
  }, [props.hg, props.clientHeight]);

  function calculateMinRatio(screenWidth) {
    var ratio = 1
   if (screenWidth >= 1920) {
      // 30 - 12
      ratio = 26
    } else if (screenWidth >= 860) {
      // 40 - 14
      ratio = 26
    }
    return ratio / 100;
  }

  return (
    <section id="digital-world" ref={props.sectionRef} class={`relative bg-colorNavLink w-full nav-sm:flex justify-around overflow-hidden section1`} style={sectionStyle}>

      <div className='w-full nav-sm:w-1/2 flex flex-col flex-1 pb-0 px-4 nav-sm-px-16'>
        <div className={` s1-margin-top relative z-20 w-full pl-[24px] c-lg:pl-[77px] c-xl:pl-[148px]`}>
          <div className='w-full h-full text-white flex flex-col flex-1'>
            <p className={`${(props.hg) ? 'text-[48px] md:text-[60px]' : 'text-[36px] md:text-[60px] '} c-xl:text-[68px] 3xl:text-[74px] font-bold tracking-ls6 nav-sm:tracking-ls4 inline-block md:whitespace-nowrap`}>Digitalizing the world</p>
            <p className={`${props.hg ? 'text-[18px] md:text-[20px]' : 'text-[14px] md:text-[20px]'} c-xl:text-[24px] 3xl:text-[29px] tracking-ls8 nav-sm:tracking-ls5 mt-[14px] nav-sm:mt-[22px] c-xl:mt-[44px] pr-[24px] `}>Specialized in crafting elegant, high-performance mobile apps that enhance growth and engagement.</p>
            <p className={`${(props.hg) ? 'text-[14px] md:text-[18px]' : 'text-[14px] md:text-[18px]'} c-xl:text-[22px] 3xl:text-[22px] tracking-ls4 mt-[14px] nav-sm:mt-[22px] c-xl:mt-[44px] text-[#7e7e7e]`} onClick={handleThereIsMoreButtonClicked}>There is more</p>
          </div>
        </div>
      </div>

      <div className="w-full nav-sm:w-1/2 flex z-20 items-center justify-center min-h-[100%]
        px-[42px] c-md:px-[114px] nav-sm:px-[42px] c-hd:px-[54px]
        pt-[20px] c-1000:pt-[140px] c-2lg:pt-[12vh] c-xl:pt-[13vh]
        pb-[48vw] c-md:pb-[40vw] nav-sm:pb-[10%] c-hd:pb-[90px] c-2lg:pb-[12vh] c-xl:pb-[13vh]">
        <div className="flex h-full items-center justify-end rotate-[-4deg] rotate-animation">
          <img
            src={MockAndroid}
            className={`object-fit max-w-full max-h-full ${animate ? 'image-animate' : ''}`}
            loading="lazy"
          />
        </div>
        <div className="flex h-full items-center justify-start rotate-[3deg] rotate-animation2">
          <img
            src={MockIphone}
            className={`object-fit max-w-full max-h-full ${animate ? 'image-animate2' : ''}`}
            loading="lazy"
          />
        </div>
      </div>

      <div class="absolute bg-colorSun sun sun-sm block nav-sm:hidden"></div>
      <div class="absolute bg-colorSun sun sun-ratio hidden nav-sm:block"></div>

      <img src={GravisunTextImage} className='hidden nav-sm:block gravisun-texture absolute bottom-[28px] right-[24px]' loading="lazy" />

    </section>
  )
}
