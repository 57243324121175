import React from 'react'
import { useEffect, useState, useRef } from 'react';

export default function Section8(props) {

    const [currentIndex, setCurrentIndex] = useState(0);
    const [isAnimating, setIsAnimating] = useState(false);
    const containerRef = useRef(null);

    const titles = [
        "Contact us for a consultation",
        "Provide an overview of your business",
        "Receive expert technical consultation",
        "Allow us to assist you in accelerating your business’s growth",
        "Get in touch to digitize your product"
    ];

    useEffect(() => {
        const interval = setInterval(() => {
            setIsAnimating(true);
            setTimeout(() => {
                setCurrentIndex((prevIndex) => (prevIndex + 1) % titles.length);
                setIsAnimating(false);
            }, 500);
        }, 3500);

        return () => clearInterval(interval);
    }, [titles.length]);

    const openCompanyEmail = () => {
        window.open('mailto:info@gravisun.com');
    }

    return (
        <section ref={props.sectionRef} id='connect' class={` ${(props.hg) ? '' : 'nav-sm:min-h-screen '} section8 relative w-full flex flex-col items-center justify-center  bg-colorBackContactUs`} >
            <div className='flex w-full flex-col felx-1  h-full items-center text-center px-[16px] md:px-[64px] overflow-hidden '>
                <div className='w-full flex justify-center items-center '>
                    <div ref={containerRef} className='w-[80%] c-md:w-2/3 c-lg:w-1/2 c-xl:w-[40%] flex items-end justify-end h-[300px]  ' >
                        <p className={`font-bold w-full text-colorPrimary text-[30px] c-md:text-[36px] c-lg:text-[50px] c-xl:text-[60px] mb-4 py-12 ${isAnimating ? 'fade-out-down' : 'fade-in-down'}`}>{titles[currentIndex]}
                        </p>
                    </div>
                </div>
                <div className='w-full flex-1 flex-col flex justify-center items-center  py-[42px]'>
                    <p className='w-full flex flex-1 c-md:w-2/3 c-lg:w-1/2 c-xl:w-[40%] px-3 md:px-0  text-[14px] c-md:text-[20px] c-xl:text-[30px] text-[#292929]'>
                        We're passionate about turning your vision into a reality. We specialize in creating high-performing mobile apps specifically tailored for clients. Our team is committed to delivering top-notch, innovative solutions that elevate your online presence and drive business growth.
                    </p>
                </div>
                <div className='w-full  flex flex-col justify-start items-center h-[300px]'>
                    <div className='tracking-ls1 text-[#DF6930] flex items-center justify-center mt-[42px] bg-[#f4f4f4] rounded-[10px] button-shadow-connect cursor-pointer' onClick={openCompanyEmail}>
                        <p className='!cursor-pointer px-[28px] leading-[24px] py-[18px] font-bold text-[24px]'>Contact us</p>
                    </div>
                    <p className='text-colorBlack30 mt-[39px] '>info@gravisun.com</p>
                </div>
            </div>
        </section>
    )
}







