import React, { useRef } from 'react'
import backgroundImage from '../assets/background_team.webp'
import icMore from '../assets/icon/ic_more.webp'
import icEdit from '../assets/icon/ic_edit.webp'
import icRecord from '../assets/icon/ic_voice.webp'
import icAttachment from '../assets/icon/ic_attachment.webp'
import profileGrasisun from '../assets/profile/profile_gravisun.webp'
import profileAmin from '../assets/profile/profile_amin.webp'
import profileAlex from '../assets/profile/profile_alexandra.webp'
import profileHans from '../assets/profile/profile_hans.webp'
import chatMessageImage from '../assets/chat_picture.webp'
import { useEffect, useState } from 'react';

export default function SectionTeam(props) {

    const [isSmallScreen, setIsSmallScreen] = useState(false);
    const [chatHeight, setChatHeight] = useState(675);

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth < 1280);
            const sizeBasedOnScreen = parseFloat(props.clientHeight) * 0.68;
            const prefferedSize = Math.max(sizeBasedOnScreen, 675)
            setChatHeight(Math.min(prefferedSize, 918));
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, [props]);

    const [titleMarginTop, setTitleMarginTop] = useState(50)

    useEffect(() => {
        const teamContainer = document.getElementById("team");
        if (teamContainer && props.clientHeight) {
            const containerHeight = teamContainer.offsetHeight;
            const diff = Math.abs(containerHeight - props.clientHeight);
            if (diff < 228 && props.clientWidth >= 1280) {
                setTitleMarginTop(calcMarginTop(diff))
            } else {
                setTitleMarginTop(getTitleDefaultMarginTop());
            }
        } else {

        }
    }, [props.clientHeight]);

    function calcMarginTop(diff) {
        const marginTop = getTitleDefaultMarginTop();
        return Math.min(Math.abs(diff - marginTop), 36);
    }

    function getTitleDefaultMarginTop() {
        var marginTop = 52;
        if (props.clientWidth >= 640) {
            marginTop = 56;
        }
        if (props.clientWidth >= 1280) {
            marginTop = 90;
        }
        if (props.clientWidth >= 1919) {
            marginTop = 125;
        }
        return marginTop;
    }

    return (
        <section id="team" ref={props.sectionRef} class={` ${(props.hg) ? '' : 'flex nav-sm:min-h-screen'} section7 bg-no-repeat bg-cover`} style={{ backgroundImage: `url(${backgroundImage})` }} >
            <div className='flex-1 flex flex-col items-center'>
                <p className='text-[36px] c-lg:text-[48px] c-xl:text-[60px] text-white font-bold' style={{marginTop: `${titleMarginTop}px`}}>TEAM</p>
                <div className='w-full h-full flex items-center justify-center '>
                    <div className='flex  w-full md:w-4/5 c-lg:w-4/5 c-xl:w-3/5  mb-12 mt-[40px] overflow-hidden justify-center' style={{ maxHeight: `${chatHeight}px` }}>
                        <div id="section-contact" class={`w-[432px] hidden c-lg:flex flex-col chat-list-background bg-[#0000004d] pb-[8px]`}>
                            <div className='flex justify-between mt-[24px] w-full '>
                                <p className='text-white text-[40px] ml-[40px]'> Messages</p>
                                <div className='flex mr-[30px]'>
                                    <img src={icMore} className='size-[60px] mx-1' loading="lazy"/>
                                    <img src={icEdit} className='size-[60px] mx-1' loading="lazy"/>
                                </div>
                            </div>
                            {/* Search */}
                            <div className='px-[15px] mt-[22px]'>
                                <div class='flex items-center w-full h-[60px] chat-search-box rounded-[16px]'>
                                    <p className='text-white text-[24px] opacity-60 ml-[24px] my-[15px]'>Search</p>
                                </div>
                            </div>
                            {/* Users Grid */}
                            <div className='overflow-scroll scrollbar-hidden flex-grow scrollbar-hide w-[432px] mt-[25px]' style={{ paddingBottom: '18px' }}>
                                <ContactsGrid img={profileGrasisun} loading="lazy"/>
                                <ContactChatHistoryList />
                            </div>
                        </div>
                        <div id="section-chat" className={`max-w-[690px] c-lg:max-w-[730px] ${(!isSmallScreen) ? 'section-chat-container' : 'section-chat-container-sm'} relative w-full md:bg-[#80808080] mx-6 md:mx-0`}>
                            <div class={`flex flex-col space-between ${isSmallScreen ? 'chat-content-background-sm' : 'chat-content-background'} h-full w-full bg-[#80808080]`}>
                                <div className='overflow-hidden flex-grow scrollbar-hide px-[16px] md:px-[40px] flex flex-col justify-end pt-[10px] c-md:pt-0 '>
                                    <div className='flex flex-col-reverse overflow-y-auto scrollbar-hide '>
                                        <ChatBubble key={"3"} message={"Great Team! My name is Hans, I’m the engine of the development. Nice to meet you!"} profilePic={profileHans} />
                                        <ChatBubble key={"2"} message={"What a title!👏🏼 Hi everyone, I’m Alexandra Visual & Design Director. If is comes to UI/UX I’m here to help!"} profilePic={profileAlex} />
                                        <ChatBubble key={"1"} message={"Hi! 👋 I'm Amin, the founder of Gravisun! We're Glad to have you here."} profilePic={profileAmin} />
                                        <img src={chatMessageImage} className='mb-[5px] nav-sm:mb-[4px] mt-[9px] nav-sm:mt-[44px] w-full md:w-4/5 pl-[68px] nav-sm:pl-[80px]' loading="lazy"/>
                                    </div>
                                </div>
                                <div className='flex items-center justify-start gap-x-4 w-full px-[16px] md:px-[40px] mt-[16px]'>
                                    <div className='flex items-center justify-center h-[50px] md:h-[50px] hidden c-lg:flex bg-colorWhite10 text-white rounded-[32px] px-[32px]'>
                                        <p className='text-[18px] nav-sm:text-[24px]'>Love it!</p>
                                    </div>
                                    <div className='flex items-center justify-center h-[50px] md:h-[50px] hidden c-lg:flex bg-colorWhite10 text-white rounded-[32px] px-[32px]'>
                                        <p className='text-[18px] nav-sm:text-[24px]'>I’m in.</p>
                                    </div>
                                    <div className='flex items-center justify-center h-[50px] md:h-[50px] text-white join-button cursor-pointer px-[32px]' onClick={props.toggleJoinPopup}>
                                        <p className='!cursor-pointer z-10 text-[18px] nav-sm:text-[24px]'>Join the team</p>
                                    </div>
                                </div>
                                <div className='flex space-between items-center px-[16px] md:px-[40px] mb-[24px] mt-[24px]'>
                                    <img src={icAttachment} className='size-[55px] md:size-[60px]' loading="lazy"/>
                                    <div className='pl-3 w-full'>
                                        <div class='flex justify-between items-center w-full h-[55px] md:h-[60px] chat-search-box rounded-[30px]'>
                                            <p className='text-[12px] sm:text-[18px] c-2lg:text-[22px] pl-[12px] m12p:pl-[24px] text-white opacity-60'>{(!isSmallScreen) ? "To apply, click on the “Join the team” button" : "Click the “Join the team” button"}</p>
                                            <img src={icRecord} className='size-[42px] md:size-[48px] mr-[6px]' loading="lazy"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section >
    )
}

function ContactsGrid() {
    return (
        <div className="grid grid-cols-3 mx-[20px] ">
            <ContactItem name={"Gravisun"} img={profileGrasisun} isSelected={true} />
            <ContactItem name={"Amin"} img={profileAmin} />
            <ContactItem name={"Alexandra"} img={profileAlex} />
            <ContactItem name={"Hans"} img={profileHans} />
        </div>
    )
}

function ContactItem(props) {
    return (
        <div class={` ${props.isSelected ? 'chat-profile-selected' : ''} flex flex-col items-center justify-center h-[164px] w-[130px]`}>
            <img src={props.img} className='h-[100px] w-[100px]' loading="lazy"/>
            <p className='text-white text-[20px] opacity-60 mt-1'>{props.name}</p>
        </div>
    )
}

function ChatBubble({ message, profilePic }) {
    return (
        <div className="flex items-end my-[8px] nav-sm:my-[15px] w-full md:w-4/5">
            <img src={profilePic} className="w-[57px] h-[57px] nav-sm:w-[64px] nav-sm:h-[64px] rounded-full" loading="lazy"/>
            <div className="bg-colorWhite30 text-white p-[16px] rounded-[20px] relative ml-[11px] nav-sm:ml-[16px]">
                <div className="overflow-hidden relative">
                    <p className='text-[14px] c-md:text-[18px] c-mdnav-sm:text-[22px] opacity-[0.8] leading-[28px]'>{message}</p>
                </div>
            </div>
        </div>
    );
};

function ContactChatHistoryList() {
    return (
        <div className='flex flex-col mt-[10px]'>
            <ContactChatHistoryItem
                name={"Amin"}
                message={"Hey! Just a reminder that we are launching a new project and need you on the board! 😄"}
                profilePic={profileAmin}
                lastItem={true}
                time={"11:00 AM"} />
            <ContactChatHistoryItem
                name={"Alexandra"}
                message={"Hey! How are you feeling today? Can’t wait to meet w/ our new client!"}
                profilePic={profileAlex}
                lastItem={false}
                time={"10:12 AM"} />
            <ContactChatHistoryItem
                name={"Hans"}
                message={"Morning! Check out how smooth the app runs after all the optimizations!"}
                profilePic={profileHans}
                lastItem={true}
                time={"9:04 AM"} />
        </div>
    )
}

function ContactChatHistoryItem({ name, message, profilePic, time, lastItem }) {
    return (
        <div className={`flex w-full px-[40px] mt-[15px] ${(lastItem == false) ? 'mb-[15px]' : 'mb-0'}`}>
            <img src={profilePic} className="w-[64px] h-[64px] rounded-full mt-3" loading="lazy"/>
            <div className="text-white rounded-lg relative ml-[12px]">
                <div className='flex justify-between'>
                    <p className='text-white text-[24px] leading-[28px]'>{name}</p>
                    <p className='text-colorWhite60 text-[18px] leading-[25px]'>{time}</p>
                </div>
                <div className="overflow-hidden relative">
                    <p className='text-md text-colorWhite60 text-[18px] leading-[25px] mt-[4px]'>{message}</p>
                </div>
            </div>
        </div>
    )
}