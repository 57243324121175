import React from 'react'
import { useState, useEffect } from 'react'
import bgServices from '../assets/services/bg-services-blured.webp'
import imgServiceDevelopment from '../assets/services/service-development.webp'
import imgServiceDesign from '../assets/services/service-design.webp'
import imgServiceManagement from '../assets/services/service-management.webp'
import imgServiceOutsourcing from '../assets/services/service-outsourcing.webp'
import imgServiceRework from '../assets/services/service-rework.webp'
import imgServiceConsult from '../assets/services/service-consult.webp'
import Atropos from 'atropos/react'

export default function Section2(props) {

  return (
    <section ref={props.sectionRef} id='services' class={` ${(props.hg) ? 'min-h-[900px]' : 'nav-sm:min-h-screen'}  items-center relative section2 w-full bg-no-repeat bg-cover flex flex-col flex-grow items-center background-blur`} style={{ backgroundImage: `url(${bgServices})` }} >

      <div className='mt-[52px] c-md:mt-[56px] c-lg:mt-[90px] c-xl:mt-[125px] text-white flex flex-col items-center justify-center'>
        <p className='text-[36px] c-lg:text-[50px] c-xl:text-[60px] font-bold'>WHAT WE DO?</p>
      </div>

      <div className='service-grid-width flex flex-1 flex-col items-center justify-start h-full'>
        <p className='text-white text-[18px] c-md:text-[20px] c-xl:text-[30px] leading-[30px] px-[24px] c-xl:leading-[45px] mt-[20px] text-center'>Here are some of the key services we proudly offer our clients!</p>

        <div className='w-full h-full flex flex-1 items-center justify-center mt-[-12px] mt-[64px] c-lg:mt-[0px] '>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 justify-center gap-y-0 w-full">
            <ServiceItem name="Development" image={imgServiceDevelopment} onServiceClicked={(e) => props.onServiceClicked(e, 0)} index={1} />
            <ServiceItem name="Design & Vision" image={imgServiceDesign} onServiceClicked={(e) => props.onServiceClicked(e, 1)} index={2} />
            <ServiceItem name="Operation" image={imgServiceManagement} onServiceClicked={(e) => props.onServiceClicked(e, 2)} index={3} />
            <ServiceItem name="Outsource" image={imgServiceOutsourcing} onServiceClicked={(e) => props.onServiceClicked(e, 3)} index={4} />
            <ServiceItem name="Rework" image={imgServiceRework} onServiceClicked={(e) => props.onServiceClicked(e, 4)} index={5} />
            <ServiceItem name="Consultation" image={imgServiceConsult} onServiceClicked={(e) => props.onServiceClicked(e, 5)} index={6} />
          </div>
        </div>
      </div>

    </section>
  )
}

function ServiceItem({ image, name, onServiceClicked, index }) {

  const [isLargeScreen, setIsLargeScreen] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth >= 1024);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const rotationAngle = index % 3 === 1 ? 12 : index % 3 === 2 ? 0 : -12;

  const rotationSide = index <= 3 ? 'bottom' : 'top';

  const itemSize = (() => {
    if (isLargeScreen && rotationAngle == 0) {
      if (index == 2) {

        return 'aspect-serviceratiom mt-[0.93vw]';
      } else if (index == 5) {
        return 'aspect-serviceratiom';
      } else {
        return `aspect-serviceratio`;
      }
    } else {
      return `aspect-serviceratio`;
    }
  })

  const paddingStyle = "py-[8px] c-2lg:py-[25px] px-[6px] c-2lg:px-[14px]";
  const transformStyle = isLargeScreen
  ? {
      transform: `perspective(1000px) rotateY(${rotationAngle}deg)`,
      transformStyle: 'preserve-3d',
      transformOrigin: rotationSide,
    }
  : undefined;

  return (
    <div className={`${itemSize()} cursor-pointer flex items-center justify-center`} onClick={onServiceClicked}>
      
      <div className={`w-full h-full block lg:hidden ${paddingStyle}`} style={transformStyle}>
        <div key={name} className='w-full h-full'>
          <ServiceItemInside image={image} name={name} />
        </div>
      </div>

      <div className='w-full h-full hidden lg:block flex flex-col' style={transformStyle}>
        <Atropos key={name} className={`w-full h-full ${paddingStyle}`} shadow={false} highlight={true} scale={false} >
          <ServiceItemInside image={image} name={name} />
        </Atropos>
      </div>
    </div>
  );
}

function ServiceItemInside(props) {
  return (
    <div className="h-full rounded-[28px] overflow-hidden" >
      <div className='overflow-hidden relative h-full'>
        <img src={props.image} className='w-full object-cover h-full object-cover' loading="lazy"/>
        <div className='absolute inset-0 flex items-center justify-center h-full w-full'>
          <p className='text-[38px] md:text-[42px] c-hd:text-[36px] c-lg:text-[44px] c-xl:text-[55px] font-bold text-white text-center tracking-lsservice w-full h-full flex items-center justify-center'>{props.name}</p>
        </div>
      </div>
    </div>
  );
}