import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyD_oL2uYPa53nDKcHKtKaJnDAp52eDzQvE",
  authDomain: "gravisun-website.firebaseapp.com",
  projectId: "gravisun-website",
  storageBucket: "gravisun-website.appspot.com",
  messagingSenderId: "734244203617",
  appId: "1:734244203617:web:404ded6e1d2e4bce0d0e20",
  measurementId: "G-DB3PQ9B1TG"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);


export { db, storage };