import React from 'react'
import { useState, useRef } from 'react';
import Nav from './Nav';
import '../index.css';
import '../scroll.css';
import '../notifStyle.css';
import '../contactStyle.css';
import '../sun.css';
import '../sections.css';
import '../mock.css';
import Section1 from './Section1';
import Section2 from './Section2';
import Section3 from './Section3';
import Section4 from './Section4';
import Section5 from './Section5';
import Section6 from './Section6';
import Section8 from './Section8';
import SectionTeam from './SectionTeam';
import JoinUsPopup from './JoinUsPopup';
import { useEffect } from 'react';
import Footer from './Footer';
import ServicePopup from './ServicePopup';
import { useInView } from "react-intersection-observer";
import Modal from 'react-modal';

Modal.setAppElement('#root');


export default function Home() {

  const [isJoinPopupVisible, setJoinPopupVisible] = useState(false);
  const [isServicesPopupVisible, setServicesPopupVisible] = useState(false);
  const [selectedServiceOrder, setSelectedServiceOrder] = useState(0);
  const [servicesPopupStyle, setServicesPopupStyle] = useState({});
  const [navbarColor, setNavbarColor] = useState('');
  const [statusbarColor, setStatusbarColor] = useState('#292929');
  const cache = useRef('#292929');

  const [section1Ref, section1InView, entry1] = useInView({ threshold: 0.1 });
  const [section2Ref, section2InView] = useInView({ threshold: 0.1 });
  const [section3Ref, section3InView] = useInView({ threshold: 0.1 });
  const [section4Ref, section4InView] = useInView({ threshold: 0.1 });
  const [section5Ref, section5InView] = useInView({ threshold: 0.1 });
  const [section6Ref, section6InView] = useInView({ threshold: 0.1 });
  const [section7Ref, section7InView] = useInView({ threshold: 0.1 });
  const [section8Ref, section8InView] = useInView({ threshold: 0.1 });
  const [section6Ref2, section6InView2] = useInView({ threshold: 0.7 });
  const [sectionFooterRef, sectionFooterInView] = useInView({ threshold: 0.1 });

  const toggleJoinPopup = () => {

    let newValue = !isJoinPopupVisible;
    setJoinPopupVisible(newValue);

    if (newValue == true) {
      setScrollPosition(window.scrollY);
    }
    if (newValue == true) {
      setStatusbarColor("#211F22");
    } else {
      setStatusbarColor(cache.current);
    }
  };

  const toggleServicesPopup = (e, selectedOrder) => {

    if (e != null) {
      const rect = e.target.getBoundingClientRect();
      const centerX = rect.left + rect.width / 2;
      const centerY = rect.top + rect.height / 2;

      setServicesPopupStyle({
        left: centerX,
        top: centerY,
      });
    }

    if (selectedOrder > 0) {
      setSelectedServiceOrder(selectedOrder);
    } else {
      setSelectedServiceOrder(0);
    }

    let newValue = !isServicesPopupVisible
    // if (newValue == true) {
    //   setScrollPosition(window.scrollY);
    // }

    if (newValue == true) {
      setStatusbarColor("#181717");
    } else {
      setStatusbarColor(cache.current);
    }
    setServicesPopupVisible(newValue);
  };

  const onContactUsClicked = () => {
    window.open('https://calendar.google.com/calendar/appointments/schedules/AcZssZ3zbwwihpttOwgWnEC5YTGA4stKnuywDa0PgKnrjWQPvjfdLBLNVSUMcYl5XroKLtlJGJmHkKH5', '_blank');
  }

  useEffect(() => {

    const handleIntersection = () => {
      var color = '';

      if (section1InView) {
        color = ('gray');
      } else if (section2InView) {
        color = 'light'
      } else if (section3InView) {
        color = 'light'
      } else if (section4InView) {
        color = ('gray');
      } else if (section5InView) {
        color = ('dark');
      } else if (section6InView) {
        color = 'light'
      } else if (section7InView) {
        color = 'light'
      } else if (section8InView) {
        color = 'light'
      } else if (sectionFooterInView) {
        color = 'light';
      } else {
        color = 'light';
      }

      if (color == 'gray') {

        setStatusbarColor('#292929');
        cache.current = '#292929';

      } else if (color == 'light') {

        setStatusbarColor('#ffffff');
        cache.current = '#ffffff';

      } else if (color == 'dark') {

        setStatusbarColor('#000000');
        cache.current = '#000000';

      } else {
        setStatusbarColor(cache.current)
      }

      setNavbarColor(color);
    };

    handleIntersection();

  },
    [section1InView, section2InView, section3InView, section4InView, section5InView, section6InView, section7InView, section8InView]
  );

  useEffect(() => {
    document.querySelector('meta[name="theme-color"]').setAttribute('content', statusbarColor);
    document.body.style.backgroundColor = '#292929';
  }, [statusbarColor]);


  const [isHeightGreater, setIsHeightGreater] = useState(false);
  const [clientWidth, setClientWidth] = useState(0);
  const [clientHeight, setClientHeight] = useState(0);

  const elementRef = useRef(null);
  const containerRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      if (elementRef.current) {
        const { clientWidth } = elementRef.current;
        const viewportHeight = window.innerHeight;
        setClientWidth(clientWidth);
        setClientHeight(viewportHeight);
        setIsHeightGreater(clientWidth > 859 && viewportHeight >= clientWidth * 1.1);
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const hdLimit = 200;

  const [sectionHeights, setSectionHeights] = useState({});
  const calculateHeights = () => {
    const sectionElements = document.querySelectorAll('section');

    const heights = {};
    const viewportHeight = window.innerHeight;

    var find = false;
    sectionElements.forEach((section, index) => {

      if (index != sectionElements.length - 1) {

        if (Math.abs(viewportHeight - section.clientHeight) > 100) {
          find = true;

        }
      }
      heights[`section${index + 1}`] = section.clientHeight;
    });

    const htmlElement = document.documentElement;
    if (find == false) {
      htmlElement.style.scrollSnapType = 'y mandatory';

      sectionElements.forEach((section, index) => {

        section.classList.add('sec');
      });

    } else {
      htmlElement.style.scrollSnapType = 'y proximate';


      sectionElements.forEach((section, index) => {

        section.classList.remove('sec');
      });
    }

    setSectionHeights(heights);
  };

  useEffect(() => {
    const timeoutId = setTimeout(calculateHeights, 20);
    const handleResize = () => {
      clearTimeout(timeoutId);
      setTimeout(calculateHeights, 20);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    // || isServicesPopupVisible
    if (isJoinPopupVisible) {
      document.documentElement.style.scrollSnapType = '';
      document.body.style.scrollSnapType = '';
      document.body.style.position = 'fixed';
      document.body.style.top = `-${scrollPosition}px`;
      document.body.style.left = '0';
      document.body.style.right = '0';

      document.documentElement.style.scrollBehavior = 'auto';
    } else {

      document.body.style.position = '';
      document.body.style.top = ``;

      requestAnimationFrame(() => {
        document.documentElement.scrollTop = scrollPosition;
        document.body.scrollTop = scrollPosition;

        document.documentElement.style.scrollBehavior = '';
      });

      setTimeout(() => {
        calculateHeights();
      }, 50);

    }
    // isServicesPopupVisible
  }, [isJoinPopupVisible, scrollPosition]);

  return (

    <div ref={elementRef} className='w-full h-screen'>

      {
        (!isJoinPopupVisible && !isServicesPopupVisible) && <Nav onContactUsClicked={onContactUsClicked} theme={navbarColor} />
      }

      <div ref={containerRef} id="cond" className="h-screen" >

        <Section1 sectionRef={section1Ref} hg={isHeightGreater} clientWidth={clientWidth} clientHeight={clientHeight} hdLimit={hdLimit} />

        <Section2 sectionRef={section2Ref} onServiceClicked={toggleServicesPopup} hg={isHeightGreater} clientWidth={clientWidth} clientHeight={clientHeight} hdLimit={hdLimit} />

        <Section3 sectionRef={section3Ref} hg={isHeightGreater} clientWidth={clientWidth} clientHeight={clientHeight} hdLimit={hdLimit} />

        <Section4 sectionRef={section4Ref} hg={isHeightGreater} clientWidth={clientWidth} clientHeight={clientHeight} hdLimit={hdLimit} />

        <Section5 sectionRef={section5Ref} hg={isHeightGreater} clientWidth={clientWidth} clientHeight={clientHeight} hdLimit={hdLimit} />

        <Section6 sectionRef={section6Ref} hg={isHeightGreater} clientWidth={clientWidth} clientHeight={clientHeight} hdLimit={hdLimit} inViewRef={section6Ref2} inView={section6InView2} />

        <SectionTeam sectionRef={section7Ref} toggleJoinPopup={toggleJoinPopup} hg={isHeightGreater} clientWidth={clientWidth} clientHeight={clientHeight} hdLimit={hdLimit} />

        <Section8 sectionRef={section8Ref} onContactUsClicked={onContactUsClicked} hg={isHeightGreater} clientWidth={clientWidth} clientHeight={clientHeight} hdLimit={hdLimit} />

        <Footer sectionRef={sectionFooterRef} />

      </div>

      <Modal
        isOpen={isJoinPopupVisible}
        onRequestClose={toggleJoinPopup}
        shouldCloseOnOverlayClick={false}
        shouldFocusAfterRender={true}
        style={{
          overlay: {
            backgroundColor: 'transparent',
            zIndex: 100
          },
          content: {
            background: 'transparent',
            border: 'none',

          },
        }}>
        {<JoinUsPopup isJoinPopupVisible={isJoinPopupVisible} toggleJoinPopup={toggleJoinPopup} />}

      </Modal>

      {<ServicePopup isServicesPopupVisible={isServicesPopupVisible} toggleServicePopup={toggleServicesPopup} selectedServiceOrder={selectedServiceOrder} updateSelectedServiceOrder={setSelectedServiceOrder} popupStyle={servicesPopupStyle} setPopupStyle={setServicesPopupStyle} clientHeight={clientHeight} />}

    </div>

  )
}
