import React from 'react'
import NetworkImage from '../assets/network.gif'

export default function Section5(props) {

    return (
        <section id='trust' ref={props.sectionRef} class={` ${(props.hg) ? '' : 'min-h-screen '} relative bg-black section5 w-full flex flex-col items-center `} >
            <div className='mt-[52px] c-md:mt-[56px] c-lg:mt-[90px] c-xl:mt-[125px] flex flex-col text-center items-center justify-center z-20 w-full nav-sm:w-[60%] c-xl:w-[37%] px-[36px] nav-sm:px-0'>
                <p className='text-[36px] c-md:text-[36px] c-xl:text-[60px] font-bold text-white'>TRUST IN THE PROCESS</p>
                <p className='text-[18px] c-md:text-[24px] c-xl:text-[30px] text-white mt-[33px] leading-[32px] c-md:leading-[39px] c-xl:leading-[45px]'>Sometimes it looks messy, however our <span className='font-bold'>experts</span> hold the leash tight!</p>
            </div>

            <div className='w-full flex flex-1 flex-col items-center justify-center mt-[-22px]'>
                <div className='flex-grow flex flex-col items-center justify-center gif-width '>
                    <img src={NetworkImage} className='w-full' loading="lazy"/>
                </div>
            </div>
        </section>
    )
}
